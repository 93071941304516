.SeaBoard_Footer {
    padding: 50px 50px 10px 50px;
    color: #00c0e5;
    font-size: 12px;
    position: relative;
    bottom: 0;
    background: #232323;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;

}

.SeaBoard_Footer .SeaBoard_Footer_Details {
    display: grid;
    grid-template-columns: 33.33fr 33.33fr 33.33fr;
}

.SeaBoard_Footer .SeaBoard_Footer_Details div:first-child {
    /* display: flex;
    align-items: center; */
}

.SeaBoard_Footer .SeaBoard_Footer_Details .links {
    color: #fff;
    text-decoration: none;
    transition: .3s;
    font-family: Quicksand !important;
    font-size: 20px;
    margin-top: 10px;
    display: block;
}

.SeaBoard_Footer .SeaBoard_Footer_Details .links:hover {
    transform: translateX(3px);
}

.SeaBoard_Footer .SeaBoard_Footer_Details div {
    padding: 0 20px;
}

.SeaBoard_Footer .SeaBoard_Footer_Details div .SeaBoard_Line {
    border-top: 1px solid rgb(190, 189, 189);
    width: 50%;
}

.SeaBoard_Footer .SeaBoard_Footer_Icons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}

.SeaBoard_Footer .SeaBoard_Footer_Icons i {
    text-decoration: none;
    background-color: #00c0e5;
    font-size: 30px;
    margin: 0 10px;
    border: 1px solid #00c0e5;
    color: #fff;
    padding: 10px;
    transition: 0.5s;
    border-radius: 50px;
}

.SeaBoard_Footer .SeaBoard_Footer_Icons i:hover {
    background: #00c0e5;
    color: #060709;
}

.SeaBoard_Footer hr {
    width: 80%;
    margin: 0 auto;
    height: 1px;
}
.SeaBoard_Footer_Details h5{
    color: #fff;
    font-size: 28px;

}
.followus-heading{
    margin-top: 20px;
    color: #fff;
    margin-bottom: 50px;
    font-size: 28px;
    text-align: center;
}

@media only screen and (max-width: 900px) {
    .SeaBoard_Footer .SeaBoard_Footer_Details {
        display: block;
    }
    .SeaBoard_Footer .SeaBoard_Footer_Details .cols:nth-child(2),
    .SeaBoard_Footer .SeaBoard_Footer_Details .cols:nth-child(3) {
        display: inline-grid;
        width: 50%;
    }
    .SeaBoard_Footer .SeaBoard_Footer_Details img {
        display: block;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 600px) {
    .SeaBoard_Footer .SeaBoard_Footer_Details .cols {
        padding-left: 16px;
        display: block !important;
        width: 100%;
        margin-bottom: 30px !important;
    }
    .SeaBoard_Footer .SeaBoard_Footer_Icons {
        display: grid;
        grid-template-columns: 33.33fr 33.33fr 33.33fr;
        text-align: center;
        grid-gap: 20px;
        width: 50%;
        margin: auto;
    }
    .SeaBoard_Footer .SeaBoard_Footer_Details img {
        width: 80%;
        display: block;
        margin: auto;
        margin-bottom: 30px;
    }
    .SeaBoard_Footer {
        font-size: 14px;
        padding: 30px 10px 10px 10px;
    }
}

@media only screen and (max-width: 500px) {
    .SeaBoard_Footer .SeaBoard_Footer_Details {
        display: block;
    }
    .SeaBoard_Footer .SeaBoard_Footer_Details div {
        padding: 0;
    }
    .SeaBoard_Footer .Copyrights {
        font-size: 12px;
    }
}