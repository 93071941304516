@font-face {
    font-family: Roboto;
    src: url(./assets/fonts/Roboto-Bold.ttf);
}

@font-face {
    font-family: Raleway;
    src: url(./assets/fonts/Raleway-VariableFont_wght.ttf);
}

@font-face {
    font-family: Oxygen;
    src: url(./assets/fonts/Oxygen-Regular.ttf);
}

@font-face {
    font-family: Lato;
    src: url(./assets/fonts/Lato-Regular.ttf);
}

@font-face {
    font-family: Exo;
    src: url(./assets/fonts/Exo-VariableFont_wght.ttf);
}

@font-face {
    font-family: Merriweather;
    src: url(./assets/fonts/Merriweather-Regular.ttf);
}

@font-face {
    font-family: OpenSans;
    src: url(./assets/fonts/OpenSans-Regular.ttf);
}

@font-face {
    font-family: PoiretOne;
    src: url(./assets/fonts/PoiretOne-Regular.ttf);
}

@font-face {
    font-family: Pacifico;
    src: url(./assets/fonts/Pacifico-Regular.ttf);
}

@font-face {
    font-family: JosefinSans;
    src: url(./assets/fonts/JosefinSans-VariableFont_wght.ttf);
}

@font-face {
    font-family: Quicksand;
    src: url(./assets/fonts/Quicksand-VariableFont_wght.ttf);
}

@font-face {
    font-family: Poppins;
    src: url(./assets/fonts/Poppins-Regular.ttf);
}

@font-face {
    font-family: Tangerine;
    src: url(./assets/fonts/Tangerine-Regular.ttf);
}

@font-face {
    font-family: Cinzel;
    src: url(./assets/fonts/Cinzel-Regular.ttf);
}

@font-face {
    font-family: Maersk;
    src: url(./assets/fonts/MaerskText-Regular.woff2);
}

@font-face {
    font-family: Maersk-Head;
    src: url(./assets/fonts/MaerskHeadline-Regular.woff2);
}
@font-face {
    font-family: british-green;
    src: url(./assets/fonts/British\ Green.ttf);
}


@font-face {
    font-family: Gilroy-regular;
    src: url(./assets/fonts/Gilroy-Medium.ttf);
}



@font-face {
    font-family: Gilroy-Bold;
    src: url(./assets/fonts/Gilroy-Bold.ttf);
}

@font-face {
    font-family: Space-Grotesk-Regular;
    src: url(./assets/fonts/SpaceGrotesk-Regular.otf);
}