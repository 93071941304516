.SeaBoard_Home {
    display: flex;
    justify-content: space-between;
    padding: 5px 50px ;
    background-color: black !important;
    position: fixed; /* Fixes the element to the viewport */
    top: 0; /* Aligns it at the top of the viewport */
    left: 10%; /* Centers horizontally */
    /*transform: translateX(-50%);  Adjusts for the width of the element */
    border-radius: 100px;
    z-index: 500;
    width: 80% !important; /* Adjust width as needed */
    box-sizing: border-box; /* Ensures padding is included in the width */
    margin-top: 10px;
}





.SeaBoard_Home.topped {
    background: -webkit-gradient(linear, left top, left bottom, from(rgb(6, 7, 9, .8)), to(rgba(40, 40, 40, 0)));
    background: linear-gradient(180deg, rgb(6, 7, 9, .8), rgba(40, 40, 40, 0));
}

.SeaBoard_Home.scrolled,
.SeaBoard_Home.topped-dark {
    background: rgb(6, 7, 9);
    padding: 5px 50px;
}

.SeaBoard_Home a:focus {
    box-shadow: none;
}

.SeaBoard_Home .SeaBoard_Logo {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: Cinzel;
}

.SeaBoard_Home .SeaBoard_Logo h3 {
    font-size: 35px;
    letter-spacing: 5px;
}

.SeaBoard_Home .SeaBoard_Logo a {
    text-decoration: none;
    color: #fafafa;
}

.SeaBoard_Home .SeaBoard_Bar {
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-family: Cinzel; */
}

.SeaBoard_Home .SeaBoard_Bar .search-box {
    width: fit-content;
    height: fit-content;
    position: relative;
}

.SeaBoard_Home .SeaBoard_Bar .input-search {
    height: 45px;
    width: 45px;
    border-style: none;
    padding: 10px;
    font-size: 18px;
    letter-spacing: 2px;
    outline: none;
    border-radius: 25px;
    transition: all .5s ease-in-out;
    /* background-color: #060709; */
    background: none;
    padding-right: 40px;
    color: #00c0e5;
}

.SeaBoard_Home .SeaBoard_Bar .input-search::placeholder {
    color: rgba(255, 255, 255, .5);
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 100;
}

.SeaBoard_Home .SeaBoard_Bar .btn-search {
    width: 50px;
    height: 50px;
    border-style: none;
    font-size: 20px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    color: #00c0e5;
    background-color: transparent;
    pointer-events: painted;
}

.SeaBoard_Home .SeaBoard_Bar .btn-search:focus~.input-search {
    width: 300px;
    border-radius: 0px;
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}

.SeaBoard_Home .SeaBoard_Bar .input-search:focus {
    width: 300px;
    border-radius: 0px;
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}

.SeaBoard_Home .SeaBoard_Bar button {
    background-color: none;
    width: 50px;
    height: 50px;
    font-size: 25px;
    border: none;
    background: none;
    color: #fff;
}

.SeaBoard_Home .SeaBoard_Bar button:focus {
    box-shadow: none;
    outline: none;
}

.SeaBoard_Home .SeaBoard_Bar a {

    padding: 0 10px;
    color: #00c0e5;
    text-decoration: none;
}

.SeaBoard_Home .elements {
    cursor: pointer;
    color: #087981;
    height: 100%;
    padding-top: 5px;
}

.SeaBoard_Home .menu .mnudropdown {
    width: 100%;
    height: 0;
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    transition: .5s;
    z-index: 1060709;
    overflow: hidden;
}

.SeaBoard_Home .menu .showmenuDropdown {
    height: max-content;
    padding: 30px;
}

.SeaBoard_Home .menu .hidemenuDropdown {
    height: 0;
}

.SeaBoard_Home .menu .mnudropdown .mnudropdownDataOnLarge {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
}

.SeaBoard_Home .menu .mnudropdown .mnudropdownDataOnSmall {
    display: none;
}

.SeaBoard_Home .menu .mnudropdown .mnudropdownDataOnSmall .DIV1 {
    display: flex;
    border-radius: 5px !important;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    margin-bottom: 10px;
}

.SeaBoard_Home .menu .mnudropdown .mnudropdownDataOnSmall .DIV1 input {
    width: 100%;
    height: 50px;
    border: 0;
}

.SeaBoard_Home .menu .mnudropdown .mnudropdownDataOnSmall .DIV1 i {
    padding: 10px;
    cursor: pointer;
}

.SeaBoard_Home .menu .mnudropdown .mnudropdownDataOnLarge .cols {
    padding: 0 60px;
}

.SeaBoard_Home .menu .mnudropdown .mnudropdownDataOnLarge .cols img {
    display: block;
    width: 100%;
    height: 250px;
    margin-bottom: 30px;
}

.SeaBoard_Home .menu .mnudropdown .mnudropdownDataOnLarge .cols .links {
    display: block;
    color: #060709;
    transition: .3s;
}

.SeaBoard_Home .menu .mnudropdown .mnudropdownDataOnLarge .cols .links:hover {
    text-decoration: none;
    transform: translateX(3px);
}

.SeaBoard_Home img {
    width: 120px;
}

.SeaBoard_Home i {
    font-size: 30px;
}

.SeaBoard_Home span {
    font-size: 20px;
    padding: 0 5px;
}

.SeaBoard_Home .search-div {
    display: auto;
}

@media only screen and (max-width: 992px) {
    .SeaBoard_Home {
        position: fixed;
        width: -webkit-fill-available;
    }
    .SeaBoard_Home .menu .showmenuDropdown {
        height: calc( 100vh - 10px) !important;
        overflow: auto;
    }
    .SeaBoard_Home .menu .mnudropdown .mnudropdownDataOnLarge {
        display: none;
    }
    .SeaBoard_Home .menu .mnudropdown .mnudropdownDataOnSmall {
        display: block;
    }
    .SeaBoard_Home .menu .mnudropdown .mnudropdownDataOnSmall img {
        width: 100%;
    }
    .search-button{
        display: none !important;
    }
    .login_button{
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .SeaBoard_Home.scrolled,
    .SeaBoard_Home.topped-dark,
    .SeaBoard_Home.topped {
        padding: 0 10px;
    }
    .SeaBoard_Home .search-div {
        display: none !important;
    }
    .SeaBoard_Home img {
        width: 60px;
    }
    .SeaBoard_Home .menu .showmenuDropdown {
        padding: 30px 10px;
    }
    .SeaBoard_Home .menu .mnudropdown .mnudropdownDataOnSmall .card {
        padding: 5px !important;
    }
}

.SeaBoard_Navbar {
    background-color: rgb(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    z-index: -1;
    opacity: 0;
    transition: .8s;
}

.SeaBoard_Navbar_Show {
    opacity: 1 !important;
    z-index: 1060709 !important;
}

.SeaBoard_Navbar .SeaBoard_Navbar_Menu_Open {
    left: 50% !important;
}

.SeaBoard_Navbar .SeaBoard_Navbar_Menu {
    position: absolute;
    height: 100vh;
    width: 50%;
    left: 100%;
    top: 0;
    background-color: rgb(255, 255, 255);
    transition: .8s .3s;
}

.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Side_Bar {
    width: 130px;
    height: 100vh;
    display: grid;
    grid-template-rows: 14.28fr 14.28fr 14.28fr 14.28fr 14.28fr 14.28fr 14.28fr;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: inherit;
    background: #ffff;
    font-family: Quicksand;
}

.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Side_Bar i {
    color: #184e7b;
    font-size: 25px;
    margin-bottom: 10px;
}

.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Side_Bar .Side_Bar_Boxes {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.6s;
    color: #184e7b;
    position: relative;
    font-size: 12px;
}

.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Side_Bar .Side_Bar_Boxes .Side_Bar_Line {
    position: absolute;
    height: 60%;
    width: 5px;
    top: 20%;
    left: 0;
    background-color: #184e7b;
    opacity: 0;
    transition: .5s;
}

.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Side_Bar .Side_Bar_Boxes:hover .Side_Bar_Line {
    opacity: 1;
}

.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu,
.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu1,
.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu2 {
    display: grid;
    grid-template-rows: 50fr 50fr;
    height: 100vh;
    width: 100%;
    padding: 30px 30px 0 160px;
}

.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu .MenuImgs,
.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu1 .MenuImgs,
.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu2 .MenuImgs {
    width: 100%;
    height: 300px;
    background-image: url('../../../assets/images/companies/QFS/9920.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu img,
.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu1 img,
.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu2 img {
    width: 100%;
}

.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu .Second_Div,
.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu1 .Second_Div,
.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu2 .Second_Div {
    width: 100%;
    display: grid;
    grid-template-columns: 50fr 50fr;
    padding: 30px;
    font-family: Poppins;
}

.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu .Second_Div .Menus,
.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu1 .Second_Div .Menus,
.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu2 .Second_Div .Menus {
    margin-bottom: 20px;
    transition: .3s;
    cursor: pointer;
}

.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu .Second_Div .Menus a,
.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu1 .Second_Div .Menus a,
.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu2 .Second_Div .Menus a {
    color: #060709;
    text-decoration: none;
}

.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu .Second_Div .Menus:hover,
.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu1 .Second_Div .Menus:hover,
.SeaBoard_Navbar .SeaBoard_Navbar_Menu .Main_Manu2 .Second_Div .Menus:hover {
    transform: translateX(3px);
}

.SeaBoard_Home .SeaBoard_Bar .search-box,
.SeaBoard_Home .SeaBoard_Bar  {
    font-size: 22px;
}
.login_button{
    background-color: #00c0e5;
    padding: 0px 10px;
    border-radius: 100px;
    margin-left: 10px;
    border: 1px solid #00c0e5;
    /* padding: 10px 10px; */
    font-family: Cinzel !important;
    text-transform: lowercase !important;
    font-variant: small-caps !important;
}
.login_button a{
    font-weight: 900 !important;
    font-family: Cinzel !important;
    color: #060709 !important;
}
@media only screen and (max-width: 600px) {
    
    .SeaBoard_Home .SeaBoard_Logo a img {
        width: 100px
    }
    .SeaBoard_Home .search-button .search-box,
    .SeaBoard_Home .SeaBoard_Bar .empPortalLink {
        display: none;
    }
    .SeaBoard_Home .SeaBoard_Logo h3 {
        font-size: 20px;
        letter-spacing: 5px;
    }
    .search-button{
        display: none;
    }
}
/* Styling for the search container */
.search-button {
    display: flex;
    align-items: center;
    color: #fff;
    height: 40px;
    /* Ensure the container fits the elements neatly */
    border: 1px solid #00c0e5;
    border-radius: 50px;
    transition: border-color 0.3s ease; /* Smooth transition for focus */
}

/* Styling for the search input field */
.search-button input {
    padding-left: 20px;
    width: 200px;
    border: none;
    border-right: none; /* Remove right border for seamless join with button */
    /* border-top-left-radius: 50px; */
    /* border-bottom-left-radius: 50px; */
    background: none;
    outline: none; /* Remove default focus outline */
    color: #f0f0f0;
}

/* Styling for the input field when focused */
.search-button input:focus {
    border-color: #00c0e5; /* Change border color when focused */
    background: none;
}

/* Styling for the search button */
.search-btns button {
    border: 1px solid #00c0e5;
    background: #00c0e5; /* Background color of the button */
    color: #fff;
    padding: 0 15px;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: -1px; /* Remove the gap between the input and button */
    transition: background-color 0.3s ease, border-color 0.3s ease; /* Smooth transition for hover */
}

/* Styling for the button on hover */
.search-btns button:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Slight background change on hover */
    border-color: #00c0e5; /* Change border color on hover */
}

/* Optional: Styling for the icon inside the button */
.search-btns i {
    padding: 5px 5px;
    border-radius: 50px;
    font-size: 18px; /* Adjust icon size */
    background-color: #00c0e5;
    color: #fff;

}
