.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background-color: #000;
    color: #00c0e5;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 80px;
    font-family: Cinzel;
    letter-spacing: 20px;
    text-transform: uppercase;
    transition: 1s;
    opacity: 1;
}

.loading-screen.fading {
    -webkit-animation:disappear .5s linear 1s alternate forwards;
    animation: disappear .5s linear 1s alternate forwards;
}

@-webkit-keyframes disappear {
    0% {
        opacity: 1
    }

    to {
        opacity: 0;
        z-index: -1
    }
}

@keyframes disappear {
    0% {
        opacity: 1
    }

    to {
        opacity: 0;
        z-index: -1
    }
}

.loading-screen div {
    position: relative;
    overflow: hidden;
    background: linear-gradient(90deg,#000,#00c0e5,#000);
    background-repeat: no-repeat;
    background-size: 80%;
    -webkit-animation: animate 3s linear infinite;
    animation: animate 3s linear infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: hsla(0,0%,100%,0)
}

@-webkit-keyframes animate {
    0% {
        background-position: -500%
    }

    to {
        background-position: 500%
    }
}

@keyframes animate {
    0% {
        background-position: -500%
    }

    to {
        background-position: 500%
    }
}

body {
    font-family: Cinzel !important;
}

h1,
h2 {
    font-family: Cinzel !important;
}

.popUp {
    opacity: 0;
    transform: scale(0.8);
    animation: poUp .5s linear 1 forwards alternate;
}

@keyframes poUp {
    50% {
        opacity: 1;
        transform: scale(1.1);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.larger {
    width: 80%;
    margin: auto;
}

@media only screen and (max-width: 1200px) {
    .larger {
        width: 100%;
        margin: auto;
    }
}


@media only screen and (max-width:600px) {
    .loading-screen{
        font-size: 40px;
    }
}