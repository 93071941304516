.Seaboard_SideBar {
    position: relative;
}

.Seaboard_SideBar .logo_in_sidebar {
    position: absolute;
    bottom: 10vh;
    left: 35px;
    width: 80%;
    display: block;
}

.Seaboard_SideBar .SideBar_Div {
    position: fixed;
    background-color: #060709;
    top: 0;
    left: 100vw;
    width: 20vw;
    height: 100vh;
    transition: 1s;
    color: #00c0e5;
    padding: 50px;
    z-index: 1001;
    display: flex;
    align-items: center;
}

.Seaboard_SideBar .SideBar_Div-left {
    display: none;
    position: fixed;
    background-color: rgb(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    width: 80vw;
    height: 100vh;
    transition: 1s;
    z-index: 1000;

}

.SHowSidebar {
    left: 80vw !important;
}

.Seaboard_SideBar .SideBar_Div .SideBar_Div_Menu h3 {
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 16px;
}

.Seaboard_SideBar .SideBar_Div .SideBar_Div_Menu {
    color: white;
    background: linear-gradient(to right, white 50%, #00c0e5 50%, #00c0e5);
    background-size: 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: 1.5s;
    position: relative;
}

.Seaboard_SideBar .SideBar_Div .SideBar_Div_Menu:hover {
    background-position: -100%;
}

.Seaboard_SideBar .SideBar_Div .SideBar_Div_Menu::after {
    content: "";
    position: absolute;
    background-color: #060709;
    height: 3px;
    width: 0;
    left: 0;
    bottom: -5px;
    transition: 0.5s;
}

.Seaboard_SideBar .SideBar_Div .SideBar_Div_Menu:hover::after {
    width: 100%;
}

.Seaboard_SideBar .SideBar_Div div a {
    text-decoration: none;
    font-family: Maersk;
}

.Seaboard_SideBar .SideBar_Div div a:hover {
    text-decoration: none !important;
}

.Seaboard_SideBar .SideBar_Div .SideBar_Div_Search input {
    border-radius: 5px 0 0 5px !important;
}

.Seaboard_SideBar .SideBar_Div .SideBar_Div_Search button {
    background-color: #00c0e5;
    color: white;
    border-radius: 0 5px 5px 0 !important;
}

.Seaboard_SideBar .la-times {
    display: none;
}

@media only screen and (max-width: 1650px) {
    .Seaboard_SideBar .SideBar_Div {
        width: 25vw;
    }
    .SHowSidebar {
        left: 75vw !important;
    }
}

@media only screen and (max-width: 1350px) {
    .Seaboard_SideBar .SideBar_Div {
        width: 35vw;
    }
    .SHowSidebar {
        left: 65vw !important;
    }
}

@media only screen and (max-width: 992px) {
    .Seaboard_SideBar .SideBar_Div {
        width: 40vw;
    }
    .SHowSidebar {
        left: 60vw !important;
    }
    .Seaboard_SideBar .SideBar_Div .SideBar_Div_Menu {
        color: white;
        background: unset;
        background-size: unset;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
        transition: 1.5s;
        position: relative;
    }
}

@media only screen and (max-width: 850px) {
    .Seaboard_SideBar .SideBar_Div {
        width: 50vw;
    }
    .SHowSidebar {
        left: 50vw !important;
    }
}

@media only screen and (max-width: 700px) {
    .Seaboard_SideBar .SideBar_Div {
        width: 60vw;
    }
    .SHowSidebar {
        left: 40vw !important;
    }
}

@media only screen and (max-width: 600px) {
    .Seaboard_SideBar .SideBar_Div {
        width: 70vw;
    }
    .SHowSidebar {
        left: 30vw !important;
    }
    .Seaboard_SideBar .la-times {
        display: block;
        position: absolute;
        top: 10px;
        right: 20px;
        color: #fff;
        font-size: 25px;
    }
}

@media only screen and (max-width: 500px) {
    .Seaboard_SideBar .SideBar_Div {
        width: 100vw;
    }
    .SHowSidebar {
        left: 0 !important;
    }
}